// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nps-container {
  background-color: var(--primary-background-color) !important;
  border: 1px solid var(--inactive-lines-color);
  border-radius: 12px 12px 0px 0px;
  height: 180px;
  position: relative;
}
.nps-container .close-nps-btn {
  position: absolute;
  top: 5%;
  right: 2%;
  transform: translate(50%, 50%);
}
@media (max-width: 768px) {
  .nps-container .close-nps-btn {
    position: absolute;
    top: 5%;
    right: 5%;
    transform: translate(50%, 50%);
  }
}
.nps-container .close-nps-btn .pi-angle-down {
  color: var(--primary-color);
}
.nps-container .nps-question-text {
  color: var(--primary-color);
  font-weight: 500 !important;
}
.nps-container .submitted-text {
  color: var(--fourth-color);
  font-weight: 500;
}
.nps-container .emoji-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  border-radius: 50%;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
