import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpClient,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { map, finalize, retry, catchError } from 'rxjs/operators';
import { AdminAuthService } from '../services/admin-auth.service';
import { versions } from '@env/versions';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { SpinnerService } from '../services/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  lang: string;
  constructor(
    private http: HttpClient,
    private injector: Injector,
    public translate: TranslateService,
    private messageService: MessageService,
    private spinnerService: SpinnerService
  ) {
    this.lang = this.translate.getDefaultLang() || 'en';
    this.translate.onLangChange.subscribe((event) => {
      this.lang = event.lang;
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.params.has('skipLoader')) {
      request = request.clone({
        params: request.params.delete('skipLoader')
      });
    } else {
      this.showLoader();
    }
    // const token: string = localStorage.getItem('token');
    // if i want to get it from auth service
    if (!request.headers.has('Skip-Interceptor')) {
      const authService = this.injector.get(AdminAuthService);
      let servicetoken = '';
      if (request.url.includes('api.nugttah.com')) {
        servicetoken = authService.getFromLocalStorage(authService.JWT_TOKEN_KEY);
      } else if (request.url.includes('wiser.nugttah.com')) {
        servicetoken = authService.getFromLocalStorage(authService.JWT_WISER_TOKEN);
      }
      if (servicetoken) {
        request = request.clone({
          setHeaders: {
            Authorization: servicetoken
            // 'Nugttah-App-Name': 'business-panel',
            // 'Nugttah-App-Version': versions.version
          }
        });
      }
    } else {
      // Remove the custom 'Skip-Interceptor' header to prevent it from being sent to the server
      request = request.clone({
        headers: request.headers.delete('Skip-Interceptor')
      });
    }
    return next.handle(request).pipe(
      // retry(1),
      catchError((error: HttpErrorResponse) => {
        // TODO: Gradually remove this error handling technique
        // a workaround to exclude the newely implemented apis (that already handles its errors) from this bad error handling
        if (error.url && error.url.includes('customers/with_stamps')) {
          return throwError(error);
        }
        if (error.url && error.url.includes('customers/with_stamps_stats')) {
          return throwError(error);
        }
        if (error.url && error.url.includes('customers/with_stamps_csv')) {
          return throwError(error);
        }
        if (error.url && error.url.includes('customer_voucher/business')) {
          return throwError(error);
        }
        if (error.url && error.url.includes('login')) {
          return throwError(error);
        }
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          let rMesssage = error.error;
          if (error.error) {
            rMesssage = error.error.message ? error.error.message[this.lang] : error.error.message;
          }
          errorMessage = rMesssage;
        }
        this.toastrError(errorMessage);
        // check signal
        if (error.status === 401 && error.url && !error.url.includes('pos_branches')) {
          localStorage.clear();
          sessionStorage.clear();
          if (error.url && !error.url.includes('login')) {
            this.toastrError('unauthorized access.');
            window.location.reload();
          }
        }
        return of(error.error);
      }),
      finalize(() => {
        this.hideLoader();
      })
    );
  }

  private showLoader(): void {
    setTimeout(() => {
      /* spinner ends after 5 seconds */
      this.spinnerService.showSpinner();
    }, 0);
  }

  private hideLoader(): void {
    setTimeout(() => {
      /* spinner ends after 5 seconds */
      this.spinnerService.hideSpinner();
    }, 50);
  }

  toastrSuccess(msg: string) {
    this.messageService.add({ severity: 'success', summary: msg });

  }

  toastrError(msg: string) {
    this.messageService.add({ severity: 'error', summary: msg });
  }

  toastrInfo(msg: string) {
    this.messageService.add({ severity: 'info', summary: msg });
  }

  toastrWarn(msg: string) {
    this.messageService.add({ severity: 'warn', summary: msg });
  }
}