import { RouterLink } from '@angular/router';
import { MenuItem } from 'primeng/api';

export const sidebarItems: MenuItem[] = [
  {
    label: 'dashboardTitle',
    img: 'assets/icons/home-line.svg',
    icon: 'home-line',
    sub_items: [
      { label: 'Current health check', routerLink: '/statistics/current-health-check/', allowFilter: true },
      { label: 'Sales', routerLink: '/statistics/sales/', allowFilter: true },
      { label: 'Business health', routerLink: '/statistics/business-health/', allowFilter: true },
      { label: 'Loyalty', routerLink: '/statistics/loyalty/', allowFilter: true },
      { label: 'Cust insights', routerLink: '/statistics/cust-insights/', allowFilter: true },
      { label: 'Menu engineering', routerLink: '/statistics/menu-engineering/', allowFilter: true },
      { label: 'Branches analysis', routerLink: '/statistics/branches-analysis/', allowFilter: true }
    ]
  },
  {
    label: 'brand',
    img: 'assets/icons/store.svg',
    icon: 'store',
    sub_items: [
      { label: 'Brand', routerLink: '/brands', allowFilter: false },
      { label: 'Branches', routerLink: '/branches', allowFilter: false },
      { label: 'Cashiers', routerLink: '/cashiers', allowFilter: false },
      { label: 'Gift cards', routerLink: '/gift-card', allowFilter: false },
      { label: 'Payouts', routerLink: '/payouts', allowFilter: false },
      { label: 'Generate invoice', routerLink: '/payment-invoices', allowFilter: false }
    ]
  },
  {
    label: 'loyalty',
    img: 'assets/icons/award-03.svg',
    icon: 'award-03',
    sub_items: [
      { label: 'Points rules', routerLink: '/points-rules', allowFilter: false },
      { label: 'Points rewards', routerLink: '/rewards', allowFilter: false },
      { label: 'Stamp cards', routerLink: '/stamps', allowFilter: false },
      { label: 'Apple wallet', routerLink: '/apple-wallet/add', allowFilter: false }
    ]
  },
  {
    label: 'reports',
    img: 'assets/icons/file.svg',
    icon: 'file',
    sub_items: [
      { label: 'Orders', routerLink: '/orders', allowFilter: false },
      { label: 'Customers', routerLink: '/customers', allowFilter: false },
      { label: 'Vouchers', routerLink: '/vouchers', allowFilter: false },
      { label: 'Orders by Nugttah', routerLink: '/pickup-transactions', allowFilter: false },
      { label: 'SMS', routerLink: '/smslist', allowFilter: false },
      { label: 'Franchise', routerLink: '/franchise', allowFilter: false },
      { label: 'Tables orders', routerLink: '/tableorders', allowFilter: false }
    ]
  },
  {
    label: 'products',
    img: 'assets/icons/package.svg',
    icon: 'package',
    sub_items: [
      { label: 'Product category', routerLink: '/product-category', allowFilter: false },
      { label: 'Products', routerLink: '/products', allowFilter: false }
    ]
  },
  {
    label: 'syncPos',
    img: 'assets/icons/refresh-ccw-05.svg',
    icon: 'refresh-ccw-05',
    sub_items: [
      { label: 'Sync Foodics', routerLink: '/sync-foodics', allowFilter: false },
      { label: 'Sync Marn', routerLink: '/sync-marn', allowFilter: false },
      { label: 'Sync Retm', routerLink: '/sync-retm', allowFilter: false },
      { label: 'Sync Odoo', routerLink: '/sync-odoo', allowFilter: false },
      { label: 'Sync Deliverect', routerLink: '/sync-deliverect', allowFilter: false }
    ]
  }
];